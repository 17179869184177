<template>
  <div class="app-container">
    <Title :data="data" />
    <div class="filter-container" style="display: flex; align-items: center">
      <download-excel
        :fields="json_fields"
        :fetch="handleExport"
        type="xls"
        name="学生成绩.xls"
        worksheet="学生成绩"
      >
        <el-button class="filter-item" style="margin-left: 10px" type="warning">
          导出成绩模板
        </el-button>
      </download-excel>
      <el-upload
        class="upload-demo"
        style="margin-right: 100px"
        accept=".xlsx,.xls"
        action=""
        :show-file-list="false"
        :auto-upload="false"
        :on-change="handleImportOrders"
      >
        <el-button class="filter-item" type="primary" icon="el-icon-upload">
          导入成绩
        </el-button>
      </el-upload>
      成绩源文件:{{ data.score_source_file ? data.score_source_file.name : "" }}
      <template v-if="data.score_source_file">
        <el-button
          class="filter-item"
          type="primary"
          style="margin-left: 10px"
          @click="download(data.score_source_file)"
        >
          下载
        </el-button>
        <el-button class="filter-item" type="danger" @click="handleDel">
          删除
        </el-button>
      </template>
      <el-upload
        v-if="!data.score_source_file"
        class="upload-demo"
        :action="qiniu.actionPath"
        :data="qiniu.postData"
        multiple
        accept=".jpg,.png,.pdf,.xls,.xlsx,.docx,.pptx"
        :on-success="uploadSuccess"
        :on-error="uploadError"
        :before-upload="beforeUpload"
        :show-file-list="false"
      >
        <el-button class="filter-item" style="margin-left: 20px" type="warning">
          上传
        </el-button>
      </el-upload>
      <el-button
        v-else
        class="filter-item"
        style="margin-left: 20px"
        type="warning"
        @click="dialogVisible = true"
      >
        重新上传
      </el-button>
    </div>
    <div class="filter-container">
      <el-input
        v-model="listQuery.keyword"
        placeholder="请输学生姓名/学号"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="学生姓名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.student ? scope.row.student.name : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="学号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.student ? scope.row.student.account : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="平时成绩" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.class_course_score
              ? scope.row.class_course_score.usual_score
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="期末成绩" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.class_course_score
              ? scope.row.class_course_score.final_score
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="总成绩" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.class_course_score
              ? scope.row.class_course_score.total_score
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="平时成绩" prop="usual_score">
          <el-input type="text" v-model="form.usual_score" clearable />
        </el-form-item>
        <el-form-item label="期末成绩" prop="final_score">
          <el-input type="text" v-model="form.final_score" clearable />
        </el-form-item>
        <el-form-item label="总成绩" prop="total_score">
          <el-input type="text" v-model="form.total_score" clearable />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="导入数据预览"
      :visible.sync="importDataDialogVisible"
      center
      width="60%"
      :close-on-click-modal="false"
      :row-class-name="tableRowClassName"
    >
      <div style="height: 500px" class="scrollbar">
        <el-scrollbar style="height: 500px">
          <el-table :data="yulanData" style="width: 1100px">
            <el-table-column label="序号" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column label="学生姓名" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.student_name || "" }}
              </template>
            </el-table-column>
            <el-table-column label="学号" min-width="110" align="center">
              <template slot-scope="scope">
                {{ scope.row.student_no ? scope.row.student_no : "-" }}
              </template>
            </el-table-column>
            <el-table-column label="平时成绩" min-width="110" align="center">
              <template slot-scope="scope">
                {{ scope.row.usual_score ? scope.row.usual_score : "-" }}
              </template>
            </el-table-column>
            <el-table-column label="期末成绩" min-width="110" align="center">
              <template slot-scope="scope">
                {{ scope.row.final_score ? scope.row.final_score : "-" }}
              </template>
            </el-table-column>
            <el-table-column label="总成绩" min-width="110" align="center">
              <template slot-scope="scope">
                {{ scope.row.total_score ? scope.row.total_score : "-" }}
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="importDataDialogVisible = false">取消</el-button>
        <el-button @click="importSaveData" type="primary" :loading="btnLoading">
          确认导入
        </el-button>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>该上传会覆盖之前上传文件,是否确定上传?</span>
      <div
        slot="footer"
        class="dialog-footer"
        style="display: flex; flex-direction: row-reverse"
      >
        <el-upload
          :action="qiniu.actionPath"
          :data="qiniu.postData"
          multiple
          accept=".jpg,.png,.pdf,.xls,.xlsx,.docx,.pptx"
          :on-success="uploadSuccess"
          :on-error="uploadError"
          :before-upload="beforeUpload"
          :show-file-list="false"
        >
          <el-button type="primary" @click="dialogVisible = false">
            确 定
          </el-button>
        </el-upload>
        <el-button @click="dialogVisible = false" style="margin-right: 20px"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getQiniuToken } from "@/api/common";
import request from "@/utils/request";
import { guid } from "@/utils/index";
import downloadExcel from "vue-json-excel";
import { excel2Json } from "@/utils/excel";
export default {
  components: { downloadExcel },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10,
        class_id: null,
        keyword: null,
      },
      total: null,
      id: null,
      list: [],
      msgList: [],
      yulanData: [],
      listLoading: false,
      dialogFormVisible: false,
      importDataDialogVisible: false,
      dialogVisible: false,
      // tableRowClassName: "",
      btnLoading: false,
      form: {
        id: "",
        class_course_id: "",
        class_student_id: "",
        usual_score: "",
        final_score: "",
        total_score: "",
      },
      textMap: {
        update: "编辑",
        create: "新增",
      },
      dialogStatus: null,
      rules: {
        usual_score: [
          { required: true, message: "平时成绩不能为空", trigger: "change" },
        ],
        final_score: [
          { required: true, message: "期末成绩不能为空", trigger: "change" },
        ],
        total_score: [
          { required: true, message: "总成绩不能为空", trigger: "change" },
        ],
      },
      data: {},
      qiniu: {
        actionPath: "",
        baseUrl: "",
        postData: {},
      },
      score_source_file: "",
      //导出
      json_fields: {
        学生姓名: "student.name",
        学号: "student.account",
        平时成绩: "ddd",
        期末成绩: "ddd",
        总成绩: "ddd",
      },
    };
  },
  created() {
    this.listQuery.class_id = this.$route.query.class_id;
    this.data = JSON.parse(this.$route.query.data);
    this.listQuery.class_course_id = this.$route.query.class_course_id;

    getQiniuToken().then((response) => {
      // token可多次使用
      this.qiniu.actionPath = response.data.uploadUrl;
      this.qiniu.baseUrl = response.data.baseUrl;
      if (!this.qiniu.postData.token) {
        this.qiniu.postData.token = response.data.token;
      }
    });

    this.getList();
    this.dataDetail();
  },
  methods: {
    //源文件详情
    dataDetail() {
      request({
        url: "/api/teacherend/eduAdmin/courseDetail",
        params: {
          id: this.listQuery.class_course_id,
        },
      }).then((res) => {
        console.log(res.data);
        this.id = res.data.id;
        this.data.score_source_file = JSON.parse(res.data.score_source_file);
        this.$forceUpdate(); //强制更新
      });
    },
    importSaveData() {
      console.log(this.yulanData);
      let sources = this.yulanData;
      for (let index = 0; index < sources.length; index++) {
        const item = sources[index];
        if (
          !item.student_no ||
          !item.usual_score ||
          !item.final_score ||
          !item.total_score
        ) {
          this.$message.error(`第${index + 1}行数据有空值!`);
          return;
        }
        delete item.student_name;
      }
      // sources.forEach((item,index) => {
      //   if (!item.student_no || !item.usual_score || !item.final_score || !item.total_score) {
      //     this.$message.error(`第${index}行数据有空值!`);
      //     return
      //   }
      //   delete item.student_name
      // });
      request({
        url: "/api/teacherend/eduAdmin/importSource",
        method: "post",
        data: {
          class_course_id: this.listQuery.class_course_id,
          sources,
        },
      })
        .then((response) => {
          this.importDataDialogVisible = false;
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.getList();
        })
        .catch((err) => {
          let error = "";
          this.msgList = err.msg;
          err.msg.forEach((item, index) => {
            console.log(item);
            // this.tableRowClassName(item)
            if (err.msg.length - 1 == index) {
              error += item + 1;
            } else {
              error += item + 1 + ",";
            }
          });
          this.$message({
            type: "error",
            message: `第${error}行,数据有误!`,
          });
          this.btnLoading = false;
        });
    },
    //导入
    handleImportOrders(file) {
      this.yulanData = [];
      file = file.raw;
      if (
        !(file.name.split(".")[1] == "xlsx" || file.name.split(".")[1] == "xls")
      ) {
        this.$message.error("上传文件格式不正确，请检查选择的文件");
        return;
      }
      excel2Json(file, 2).then((excelData) => {
        // 处理业务
        if (excelData.length <= 0) {
          this.$message.error("数据不能为空");
          return;
        }
        excelData.forEach((element, index) => {
          if (index > 0) {
            this.yulanData.push({
              student_name: element[0] || "",
              student_no: element[1] || "",
              usual_score: element[2] || "",
              final_score: element[3] || "",
              total_score: element[4] || "",
            });
          }
        });
        this.importDataDialogVisible = true;
      });
    },
    importdata() {},
    //导出成绩模板
    handleExport() {
      this.listQuery.limit = 99999999999999;
      return request({
        url: "/api/teacherend/eduAdmin/scoreList",
        method: "get",
        params: this.listQuery,
      })
        .then((result) => {
          console.log(result.data.data, "========");
          var json_data = result.data.data;
          return json_data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    //图片转换成base64
    getUrlBase64(url) {
      return new Promise((resolve) => {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        let img = new Image();
        img.crossOrigin = "Anonymous"; //允许跨域
        img.src = url;
        img.onload = function () {
          canvas.height = 300;
          canvas.width = 300;
          ctx.drawImage(img, 0, 0, 300, 300);
          let dataURL = canvas.toDataURL("image/png");
          canvas = null;
          resolve(dataURL);
        };
      });
    },
    //判断后缀名
    judgeType(name) {
      let result = "";
      let suffix = "";
      try {
        var flieArr = name.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      }
      let imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
      result = imglist.some(function (item) {
        return item == suffix;
      });
      return result;
    },
    //下载
    download(item) {
      let res = this.judgeType(item.url);
      if (res) {
        this.getUrlBase64(item.url).then((base64) => {
          let link = document.createElement("a");
          link.href = base64;
          link.download = item.name;
          link.click();
        });
      } else {
        let routeUrl = item.url.replace(/http:/, "https:");
        window.open(routeUrl+'?attname='+item.name, "_parent");
      }
    },
    beforeUpload(file) {
      this.qiniu.postData.key =
        guid() + "." + file.name.split(".")[file.name.split(".").length - 1];
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 10) {
        return "warning-row";
      } else if (rowIndex === 11) {
        return "success-row";
      }
      return "";
    },
    uploadSuccess(res, file) {
      let url = {
        url: "",
        name: "",
      };
      url.url = this.qiniu.baseUrl + "/" + res.key;
      url.name = file.name;
      this.score_source_file = url;
      this.handleImport();
    },
    handleDel() {
      this.$confirm("此操作将删除源文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.score_source_file = "";
          this.data.score_source_file = "";
          this.handleImport();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    uploadError() {},
    handleImport() {
      console.log(this.score_source_file.url, this.data);
      request({
        url: "/api/teacherend/eduAdmin/uploadScoreSourceFile",
        method: "post",
        data: {
          id: this.id,
          score_source_file: this.score_source_file,
        },
      })
        .then((response) => {
          this.data.score_source_file = this.score_source_file;
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$forceUpdate(); //强制更新
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/teacherend/eduAdmin/scoreStore",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    handleEdit(row) {
      this.resetForm();
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      if (row.class_course_score) {
        this.form = row.class_course_score;
      } else {
        this.form.class_course_id = this.listQuery.class_course_id;
        this.form.class_student_id = row.id;
      }

      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    resetForm() {
      this.form = {
        id: "",
        class_course_id: "",
        class_student_id: "",
        usual_score: "",
        final_score: "",
        total_score: "",
      };
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/teacherend/eduAdmin/scoreList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" >
.title {
  margin-bottom: 20px;
}
.content {
  align-items: center;
}
.el-table >>> .warning-row {
  background: red;
}
</style>
